.name-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  color: #2c3e50;

  .name-card__avatar {
    transition: filter 0.5s ease-out;
    background: #2c3e50;
    border-radius: 50%;
    border: solid 2px #2c3e50;

    img {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      display: block;
      filter: grayscale(100%) brightness(125%);
      opacity: 0.5;
      transition: filter 0.5s ease-out, opacity 0.5s ease-out;
    }

    img:hover {
      opacity: 1;
      filter: grayscale(0);
    }
  }

  .name-card__content {
    padding-left: 1rem;

    .content__name {
      font-size: 2rem;
      color: #2c3e50;
    }

    .content__title {
      font-size: 1rem;
    }
  }
}